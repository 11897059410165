import React, { Component, Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes, Navigate  } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss'
import store from './store';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

import isTokenExpired from './tokenValidate'; // Import the utility function



const NotFound = () => <h2>404 - Page Not Found</h2>;

store.subscribe(()=>{
  console.log(store.getState());    
})
const App = ()=> {  
  const [loggedIn, setLoggedIn] = useState(false);
  // const token = useSelector((state) => state.token);

  


  useEffect(()=>{    
  let token = localStorage.getItem('token');  
  if(token){
    setLoggedIn(true)
  }else{
    setLoggedIn(false)
  }  
    
  },[])
    

    return (
      <HashRouter>
        <Suspense fallback={loading}>
            <Routes>            
              <Route
              path="*"
              element={!loggedIn ? <Navigate to="/login" /> : <DefaultLayout />}
            />
            <Route
              path="/login"
              element={loggedIn ? <Navigate to="/" /> : <Login />}
            />
         
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Suspense>
      </HashRouter>
    )
  
}

export default App
